<template>
  <b-modal id="modal-market-place" hide-footer v-model="marketPlace">
    <template #modal-header>
      <b-row class="header-filter no-gutters">
        <b-col lg="10" class="ft-title">Connect New Marketplace</b-col>
        <b-col class="text-right">
          <button
            type="button"
            aria-label="Close"
            class="close text-right"
            @click="hide"
          >
            ×
          </button>
        </b-col>
      </b-row>
    </template>
    <div class="market-place-container">
      <div v-for="(list, n) of marketplace_channel" :key="n">
        <!-- :src="
            require(`@/assets/icons/marketplace/${list.name.toLowerCase()}.png`)
          " -->
        <img
          @click="$emit('handleImageClick', list.name)"
          class="market-place-image-logo"
          :src="list.image_url"
          alt="image market place logo"
        />
      </div>
    </div>
    <!-- <template #modal-footer class="py-0">
      <div class="d-flex justify-content-between w-100">
        <b-button
          class="btn-cancel btn-width"
          @click="$bvModal.hide('modal-market-place')"
          >Cancel
        </b-button>
      </div>
    </template> -->
  </b-modal>
</template>

<script>
export default {
  props: ["marketplace_channel"],
  data() {
    return {
      marketPlace: false,
    };
  },
  methods: {
    hide() {
      this.marketPlace = false;
    },
  },
};
</script>

<style scoped>
::v-deep .modal-header {
  display: block;
  background-color: var(--secondary-color);
}
.ft-title {
  color: var(--font-color);
  font-weight: 600;
  font-size: 16px;
}
.market-place-image-logo {
  width: 50px;
  height: auto;
}
.market-place-container {
  display: flex;
  padding: 1rem;
  justify-content: space-evenly;
}
</style>
